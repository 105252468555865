<template>
    <div>
        <slider/>
        <mobile ref="mobileCom"/>
        <re-select
                @indexSelectedOperator="changeSelectedOperator"
                :selectedOperator="selectedOperator"
                :operatorStaticObj="operatorStaticObj"
        />
        <re-num :showProductList="showProductList"
                :selectedOperator="selectedOperator"
                @addNum="addNum"
                @subtractNum="subtractNum"
                ref="reNumCom"
                :totalNum="totalNum"
                :selectedOperatorName="selectedOperatorName"
        />
        <order-detail :showProductList="showProductList" :selectedOperator="selectedOperator"
                      :totalPrice="totalPrice"
                      :totalNum="totalNum"
                      :selectedOperatorName="selectedOperatorName"
        />
        <div class="submit-btn" @click="submit">提交订单</div>
        <SelectPay v-show="showSelectPay" :totalPrice="totalPrice" @onGoPay="goPay"/>
        <qr :url="url"/>
    </div>
</template>
<script>

import Slider from "./Slider.vue"
import Mobile from "./mobile.vue"
import ReSelect from "./reSelect.vue"
import ReNum from "./reNum.vue"
import OrderDetail from "./orderDetail.vue"
import SelectPay from "./SelectPay.vue"
import {getProductList, orderSubmit} from "@/service/recharge"
import Qr from "@/views/components/qr"
import {getOrigin} from "@/utils/common"

export default {
    components: {
        Slider,
        Mobile,
        ReSelect,
        ReNum,
        OrderDetail,
        SelectPay,
        Qr,
    },
    data() {
        this.productType = {
            yd: '1',
            lt: '2',
            dx: '3'
        }
        console.log(getOrigin()+"/recharge")
        return {
            selectedOperator: localStorage.getItem('selectedOperator')||'lt',
            productList: [],
            showSelectPay: false,
            submitData: {
                mobile: '',
                product_type: '',
                product_list: [],
                pay_method: ''
            },
            operatorStaticObj: {
                lt: {
                    title: '联通',
                    name: 'lt',
                },
                yd: {
                    title: '移动',
                    name: 'yd',
                },
                dx: {
                    title: '电信',
                    name: 'dx',
                },
            },
            url:getOrigin()+"/recharge",
        }

    },
    computed: {
        showProductList() {
            return this.productList.filter((i) => i.type_name === this.selectedOperator)
        },
        selectedOperatorName() {
            let obj=this.operatorStaticObj[this.selectedOperator]
            return obj?obj.title:''
        },
        totalPrice() {
            return this.showProductList.reduce((pre, next) => {
                return pre + (next.actual_price / 100) * next.num
            }, 0)
        },
        totalNum() {
            return this.showProductList.reduce((pre, next) => {
                return pre + next.num
            }, 0)
        },
    },
    mounted() {
        this.getData()
        this.linkWxSdk()
    },
    methods: {
        linkWxSdk() {
            let script = document.createElement('script');
            script.src = "https://res.wx.qq.com/open/js/jweixin-1.4.0.js"
            document.body.appendChild(script)
        },
        changeSelectedOperator: function (val) {
            localStorage.setItem('selectedOperator',val)
            this.selectedOperator = val
        },
        async getData() {
            const {data} = await getProductList();
            let productList=JSON.parse(localStorage.getItem("productList"))||[]
            const n = data.map(i => {
                const productObj=productList.find(p=>p.product_id===i.id)
                i.num = productObj?productObj.product_num:0;
                return i
            })
            this.productList = n;
        },
        addNum(id) {

            this.productList=this.productList.map((i)=>{
                console.log(i.id,id)
                if(i.id===Number(id)){
                   i.num=1
                }else{
                    i.num=0
                }
                return i
            })
            // let obj = this.productList.find(i => i.id == id)
            // let idx = this.productList.findIndex(i => i.id == id)
            // this.$set(this.productList, idx, {
            //     ...obj,
            //     num: obj.num + 1
            // })
        },
        subtractNum(id) {
            let obj = this.productList.find(i => i.id == id)
            if (obj.num <= 0) {
                return
            }
            let idx = this.productList.findIndex(i => i.id == id)
            this.$set(this.productList, idx, {
                ...obj,
                num: obj.num - 1
            })
        },
        productTypeValid() {
            return this.productType[this.selectedOperator]
        },
        onBridgeReady(data) {
            // {
            //     "appId": jsapi_sign.appId,                  //公众号名称，由商户传入
            //     "timeStamp": jsapi_sign.timeStamp,        //时间戳，自1970年以来的秒数
            //     "nonceStr": jsapi_sign.nonceStr,           //随机串
            //     "package": jsapi_sign.package,
            //     "signType": jsapi_sign.signType,            //微信签名方式：
            //     "paySign": jsapi_sign.paySign               //微信签名
            // }
            //
            /* eslint-disable */
            console.log(WeixinJSBridge)
            /* eslint-disable */
            WeixinJSBridge && WeixinJSBridge.invoke(
                'getBrandWCPayRequest', data,
                function (res) {
                    console.log("WeixinJSBridge res", res);
                    if (res.err_msg == "get_brand_wcpay_request:ok") {
                        // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                        console.log("支付成功!");
                        // linkToSuccess();
                        // setIsPaid(true);
                    } else {
                        // alert("用户遇到错误或者主动放弃!");
                        console.log("统一处理为用户遇到错误或者主动放弃");
                    }
                });
        },
        wcPay(config) {


            if (typeof WeixinJSBridge == "undefined") {
                if (document.addEventListener) {
                    document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
                } else if (document.attachEvent) {
                    document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
                    document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
                }
            } else {
                this.onBridgeReady(config);
            }
        },
        async submit() {
            let mobileCom = this.$refs.mobileCom
            let mobile = mobileCom.valid()
            this.submitData.mobile = mobile

            this.submitData.product_type = this.productTypeValid()


            let reNumCom = this.$refs.reNumCom;
            let product_list = reNumCom.productListValid()
            this.submitData.product_list = product_list


            this.showSelectPay = true
        },
        async goPay(pay_method) {
            // if(pay_method===3){ //支付宝
            //
            // }
            if (navigator.userAgent.match(/MicroMessenger/i)) {
                pay_method = 1
            }
            this.submitData.pay_method = pay_method;
            let {config} = await orderSubmit(this.submitData)
            localStorage.removeItem('selectedOperator')
            localStorage.removeItem('productList')
            if (config.pay_url) {
                window.location.href = config.pay_url
                return
            }
            this.wcPay(config)


        }
    }
}
</script>
<style>
body {
    background: #F7F8F9;
}
</style>
<style scoped>

.submit-btn {
    margin: 0.6rem auto 2.4rem;
    width: 14.04rem;
    height: 1.68rem;
    background: #3D7FFF;
    border-radius: 0.84rem;
    text-align: center;
    line-height: 1.68rem;
    font-size: 0.64rem;
    font-family: PingFangSC;
    font-weight: 400;
    color: #FFFFFF;
}

</style>
