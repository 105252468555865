<template>
    <div class="pay-modal">

        <div class="pay-container">
            <h4 class="h4">支付订单</h4>
            <div class="total-price">￥{{totalPrice}}</div>
            <ul class="pay-ul">

                <li :class="`pay-li pay-li-${pay.pay_method===selectedPay?'active':''}`" v-for="pay in payArr" :key="pay.name" :data-val="pay.pay_method" @click="changeSelectedPay">
                    <img class="pay-img" :src="require(`../../assets/${pay.name}.png`)"/>
                    <p class="pay-txt">{{pay.title}}</p>
                </li>
            </ul>
            <div class="submit-btn" @click="goPay">确认付款</div>
        </div>
    </div>
</template>

<script>

   import {weiXinInner} from "@/utils/common"

    export default {
        props:{
            totalPrice:Number
        },
        components: {

        },
        computed: {
        },
        data() {
            return {
                payArr:[
                    {
                        pay_method:2,
                        title:'微信支付',
                        name:'wechat'
                    },
                ],
                selectedPay:2,
            }
        },
        mounted() {
           if(!weiXinInner()){
               this.payArr.push({
                       pay_method:3,
                       title:'支付宝支付',
                       name:'ali'
               })
           }
        },
        beforeUpdate() {

        },
        methods:{
            changeSelectedPay(e){
                console.log(e.currentTarget.dataset.val)
                this.selectedPay=Number(e.currentTarget.dataset.val)
            },
            goPay(){
                console.log('go')
                this.$emit('onGoPay',this.selectedPay)
            },

        }

    }
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .pay-modal {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(0,0,0,0.3);
        display: flex;
        flex-direction: column;
        -webkit-box-orient: vertical;
        -ms-flex-direction: column;
        -webkit-flex-direction: column;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        -webkit-justify-content: flex-end;
        justify-content: flex-end;
        z-index: 9999;
    }

    .pay-container {
        background: #fff;
        border-radius: 0.24rem 0.24rem 0 0;
        flex: 0;
    }

    .pay-container .h4 {
        border-bottom: 0.02rem solid rgba(0, 0, 0, 0.05);
        line-height: 2.06rem;
        text-align: center;
        font-size: 0.64rem;
        font-family: PingFangSC;
        font-weight: 600;
        color: #262626;
    }

    .total-price {
        padding-top: 1.3rem;
        text-align: center;
        font-size: 1.2rem;
        font-family: PingFangSC;
        font-weight: 600;
        color: #E21414;
        line-height: 0.96rem;
    }

    .pay-ul {
        padding: 0.3rem 0.98rem 0 0.58rem;
    }

    .pay-li {
        position: relative;
        display: flex;
        padding: 0.9rem 0;
    }

    .pay-li:after {
        position: absolute;
        content: '';
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
        background: #f2f2f2;
    }

    .pay-li-active:after {
        background: url("../../assets/pay-selected.png") no-repeat center center /0.8rem;
    }

    .pay-img {
        width: 1.62rem;
    }

    .pay-txt {
        padding-left: 0.56rem;
        font-size: 0.64rem;
        font-family: PingFangSC;
        font-weight: 400;
        color: #333333;
        line-height: 1.4rem;
    }

    .submit-btn {
        margin: 0.62rem auto 2.4rem;
        width: 14.04rem;
        height: 1.68rem;
        background: #3D7FFF;
        border-radius: 0.84rem;
        text-align: center;
        line-height: 1.68rem;
        font-size: 0.64rem;
        font-family: PingFangSC;
        font-weight: 400;
        color: #FFFFFF;
    }
</style>
