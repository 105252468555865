<template>
    <div>
        <h4 class="h4 padding-left-19">选择手机运营商<span class="span">(不支持携号转网及虚商号)</span></h4>
        <ul class="operator-ul">
            <li :class="`operator-li operator-li-${oper.name===selectedOperator?'active':''}`" v-for="oper in operatorStaticArr" :key="oper.name" :data-val="oper.name" @click="changeSelectedOperator">
                <img class="operator-img" :src="require(`../../assets/${oper.name}.png`)"/>
                <p class="operator-txt">中国{{oper.title}}</p>
            </li>
        </ul>
    </div>
</template>
<script>
    export default {
        props:{
            selectedOperator:{
                type:String,
                default:''
            },
            operatorStaticObj:{
                type: Object,
                default: ()=>{}
            }
        },
        data(){
          return {

          }
        },
        computed:{
            operatorStaticArr(){
                return Object.values(this.operatorStaticObj)
            }
        },
        //emits:["indexSelectedOperator"],
        methods:{
            changeSelectedOperator:function(e){
                this.$emit("indexSelectedOperator",e?(e.currentTarget)?.dataset?.val:'')
            }
        },
        // watch:{
        //     selectedOperator:function (newValue,oldValue) {
        //        console.log(1)
        //     }
        // },
      //  emits:["indexSelectedOperator"],
      //   setup(props,context){
      //       // onUpdated(()=>{
      //       //     console.log(props)
      //       // })
      //       // console.log(props)
      //       // watch(props.selectedOperator,()=>{
      //       //     console.log(1)
      //       // })
      //       const operatorStaticArr=[{
      //               title: '联通',
      //                   name: 'lt',
      //           }, {
      //               title: '移动',
      //                   name:
      //               'yd',
      //           },{
      //               title: '电信',
      //                   name:
      //               'dx',
      //           }]
      //
      //       return {
      //          // selectedOperator,
      //           operatorStaticArr,
      //       }
      //   }
    }

</script>
<style scoped>
    .padding-left-19 {
        padding-left: 0.38rem;
    }
    .h4 {
        padding-left: 0.66rem;
        padding-top: 1.24rem;
        font-size: 0.56rem;
        font-family: PingFangSC;
        font-weight: 500;
        color: #262626;
        text-align: left;
    }

    .h4 .span {
        font-size: 0.48rem;
        color: #ccc;
    }
    .operator-ul {
        margin-top: 0.28rem;
        display: flex;
        flex-direction: column;
    }

    .operator-li {
        box-sizing: border-box;
        display: flex;
        margin: 0.24rem 0.36rem 0;
        height: 2.04rem;
        background: #fff;
        border-radius: 0.12rem;
    }

    .operator-li-active {
        border: 0.04rem solid #3D7FFF;
        background: url("../../assets/selected.png") no-repeat right bottom /0.84rem 0.9rem;
        border-radius: 0.12rem 0.12rem 0.18rem 0.12rem;
    }

    .operator-img {
        border-radius: 0.12rem 0 0 0.12rem;
        width: 2.06rem;
    }

    .operator-txt {
        padding-left: 0.94rem;
        font-size: 0.64rem;
        font-family: PingFangSC;
        font-weight: 400;
        color: #333333;
        line-height: 2.04rem;
    }

    .operator-li.selected {
        border: 0.04rem solid #3D7FFF;
    }
</style>
