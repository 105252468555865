<template>
    <div class="mobile-container">
        <h4 class="h4">填写充值手机号<span class="span">（*必填，请核对好号码，充错需自行协商）</span></h4>
        <input v-model="mobile" class="mobile" placeholder="请输入手机号" type="text" maxlength="11"/>
        <div v-if="mobileErr" class="mobile-error">{{mobileErr}}</div>
    </div>
</template>
<script >
    export default {
        data(){
            return {
                mobile:localStorage.getItem("mobile")||'',
                mobileErr:''
            }
        },

        methods:{
            srollMobile(){
                let mobileContainer=document.querySelector('.mobile-container')
                window.scroll(0,mobileContainer.offsetTop)
            },
            valid(){

                if(!this.mobile){
                    this.srollMobile()
                    this.mobileErr='手机号不能为空'
                    throw new Error('手机号不能为空')
                }
                if(!/^1\d{10}$/.test(this.mobile)){
                    this.srollMobile()
                    this.mobileErr='手机号不正确'
                    throw new Error('手机号不正确')
                }
                this.mobileErr=''
                localStorage.setItem("mobile",this.mobile)
                return this.mobile
            }
        }
    }
</script>
<style scoped>

    .h4 {
        padding-left: 0.66rem;
        padding-top: 1.24rem;
        font-size: 0.56rem;
        font-family: PingFangSC;
        font-weight: 500;
        color: #262626;
        text-align: left;
    }

    .h4 .span {
        font-size: 0.48rem;
        color: #ccc;
    }

    .mobile {
        display: block;
        box-sizing: border-box;
        margin-top: 0.5rem;
        padding-left: 2.48rem;
        width: 100%;
        height: 2.04rem;
        line-height: 2.04rem;
        outline: none;
        border: none;
        font-size: 0.8rem;
        background: #fff url("../../assets/phone.png") no-repeat 0.86rem center /0.62rem 0.88rem;
    }
    .mobile-error{

        padding-left: 1.48rem;
        color: red;
        font-size: 0.48rem;
        line-height: 1.48rem;
    }
</style>
