<template>
    <div>
        <div class="recharge-desc-container">
          <p class="recharge-desc">本商品为话费慢充，充值时间较长，介意慎拍！</p>
            <p class="recharge-desc"></p>
            <p class="recharge-desc">一旦下单充值，不可退回，急单勿拍~</p>
            <p class="recharge-desc"></p>
            <p class="recharge-desc">充值时间：下单后72小时左右自动完成充值</p>
            <p class="recharge-desc"></p>
            <p class="recharge-desc">购买须知： 请填写需要充值的手机号即可，充值订单较多可能需要等待。 大部分1-72小时内到账，遇到维护情况下可能部分订单会超72小时返回，充值高峰如月底月初账期可能遇到运营商充值繁忙，充值耗时可能会稍有延长 ，请尽量错开高峰充值！</p>
            <p class="recharge-desc"></p>
            <p class="recharge-desc">◎注意事项：</p>
            <p class="recharge-desc"></p>
             <p class="recharge-desc">①虚拟号码如165、167、170、171、162不能充值，非空号欠费可充，欠费1个月导致空号的不能充值，损失自负无法退款</p>
             <p class="recharge-desc"></p>
             <p class="recharge-desc">②携号转网号码不能充值</p>
             <p class="recharge-desc"></p>
             <p class="recharge-desc">除以上两点特殊不能充值，其余都可以充值</p>
             <p class="recharge-desc"></p>
             <p class="recharge-desc">③当月叠加限20份，移动、联通、电信都可以</p>
             <p class="recharge-desc"></p>
        </div>
        <ul class="friendship-link clearfix">
            <li class="friendship-link-li fl" @click="gotoOrderSearch">订单查询</li>
            <li class="friendship-link-li fl" @click="gotoCustomService">微信客服</li>
        </ul>
        <div class="order-container" v-if="totalNum">
            <h5 class="order-title">订单详情</h5>
            <div class="order-list">
                <div v-for="(operator) in showProductList" :key="operator.id">
                    <div class="order-li"   v-if="operator.num">
                        <div class="clearfix">
                            <p class="order-p fl">{{operator.original_price/100}}元{{selectedOperatorName}}手机号码充值</p>
                            <p class="order-price fr">¥{{operator.actual_price/100}}</p>
                        </div>
                        <div class="clearfix">
                            <p class="order-num fr">× {{operator.num}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clearfix total">
                <p class="fr total-p">共计 {{totalNum.length}} 件<span class="width50"></span>合计：<span class="red">{{totalPrice}}</span></p>
            </div>
        </div>

    </div>
</template>

<script>

    export default {
        components: {
        },
        props:{
            showProductList:{
                type:Array,
                default:()=>[]
            },
            selectedOperator:{
                type:String,
                default:'lt'
            },
            totalNum:{
                type:Number
            },
            totalPrice:{
                type:Number
            },
            selectedOperatorName:{
                type:String,
                default:''
            }
        },
        data() {
            return {

            }
        },
        mounted() {

        },
        computed:{

        },
        destroyed() {
            console.log('destoryed')
        },
        methods:{
            gotoOrderSearch(){
                this.$router.push("/order")
            },
            gotoCustomService() {
                location.href = 'https://work.weixin.qq.com/kfid/kfc3210d9a47c95b749';
            }
        }

    }
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .recharge-desc-container {
        width: 13.4rem;
        margin: 1.24rem auto 0;
    }

    .recharge-desc {
        margin-bottom: 0.4rem;
        text-align: left;
        font-size: 0.4rem;
        font-family: PingFangSC;
        font-weight: 400;
        color: #999999;
        line-height: 0.6rem;
    }

    .friendship-link {
        margin: 0.78rem auto 0;
        width: 14.28rem;
    }

    .friendship-link-li {
        width: 3.36rem;
        height: 0.96rem;
        background: #EBF3FF;
        border-radius: 0.48rem;
        text-align: center;
        line-height: 0.96rem;
        font-size: 0.48rem;
        font-family: PingFangSC;
        font-weight: 400;
        color: #3D7FFF;
        cursor: pointer;
    }

    .order-container {
        margin: 1.2rem auto 0;
        box-sizing: border-box;
        padding: 0 0.64rem;
        width: 14.28rem;
        border-radius: 0.24rem;
        background: #fff;
    }

    .order-title {
        border-bottom: 0.02rem solid rgba(0, 0, 0, 0.05);
        padding: 0.64rem 0.08rem 0.72rem;
        font-size: 0.64rem;
        font-family: PingFangSC;
        font-weight: 600;
        color: #262626;
        line-height: 0.8rem;
    }

    .order-li {
        border-bottom: 0.02rem solid rgba(0, 0, 0, 0.05);
        padding: 0.68rem 0.08rem 0.84rem;
        font-size: 0.56rem;
        font-weight: 400;
        color: #262626;
        line-height: 0.56rem;
    }

    .order-price {
        font-weight: 600;
    }

    .order-num {
        margin-top: 0.68rem;
    }

    .total {
        padding: 1.16rem 0.08rem 2.04rem;
    }

    .total-p {
        font-size: 0.48rem;
        font-weight: 400;
        color: #262626;
        line-height: 0.72rem;
    }

    .total-p .red {
        font-size: 0.72rem;
        color: #E21414;
        font-weight: bold;
        vertical-align: middle;
    }

    .width50 {
        display: inline-block;
        width: 1rem;
    }

</style>
