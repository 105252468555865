<template>
   <div>
       <h4 class="h4 padding-left-19">{{selectedOperatorName}}号码充值</h4>
       <ul class="recharge-ul">
           <li class="recharge-li " v-for="(operator) in showProductList" :key="operator.id">
               <img class="recharge-img" :src="require('../../assets/'+(operator.original_price/100)+'.png')"/>
               <div class="recharge-content">
                   <h5 class="recharge-title">{{operator.original_price/100}}元{{selectedOperatorName}}手机号充值</h5>
                   <div class="recharge-p-c">
                       <p class="recharge-price">￥{{operator.actual_price/100}}</p>
                       <div class="recharge-num-container">
                           <div :class="`recharge-num-subtract ${operator.num<=0?'recharge-num-subtract-disabled':''}`" :data-subtract-key="operator.id" @click="subtract">-</div>
                           <div class="recharge-num">{{operator.num||0}}</div>
                           <div :class="`recharge-num-add ${operator.num>=1?'recharge-num-add-disabled':''}`" :data-add-key="operator.id" @click="add">+</div>
                       </div>
                   </div>
               </div>
           </li>
       </ul>
       <div class="error">{{error}}</div>
   </div>
</template>
<script>
    export default {
      props:{
          showProductList:{
              type:Array,
          },
          selectedOperator:{
              type:String,
              default:'lt'
          },
          totalPrice:{
              type:Number,
          },
          totalNum:{
              type:Number,
          },
          selectedOperatorName:{
              type:String,
              defalut:''
          }
      },

    data(){
      return {

          error:''
      }
    },
    watch:{

    },
      mounted() {
      },
      methods:{
          add(e){

              let id=e.currentTarget.dataset.addKey
              this.$emit("addNum",id)
          },
          subtract(e){
              let id=e.currentTarget.dataset.subtractKey
              this.$emit("subtractNum",id)
          },
          productListValid(){
              console.log(this.totalNum)
              if(this.totalNum<=0){
                  this.error="请输入数量"
                  throw new Error("请输入数量")
              }
              this.error='';
              let list=this.showProductList.filter(i=>i.num).map(i=>{
                  return {
                      product_id:i.id,
                      product_num:i.num
                  }
              })
              localStorage.setItem('productList',JSON.stringify(list))
              return list
          },
      }
    }
</script>
<style scoped>
    .h4 {
        padding-left: 0.66rem;
        padding-top: 1.24rem;
        font-size: 0.56rem;
        font-family: PingFangSC;
        font-weight: 500;
        color: #262626;
        text-align: left;
    }

    .h4 .span {
        font-size: 0.48rem;
        color: #ccc;
    }
    .recharge-ul {
        margin-top: 0.2rem;
        display: flex;
        flex-direction: column;
    }

    .recharge-li {
        display: flex;
        width: 14.28rem;
        margin: 0.32rem auto 0;
        background: #fff;
        border-radius: 0.24rem;
    }

    .recharge-img {
        width: 5.6rem;
    }

    .recharge-content {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 0.84rem 0.4rem 0.4rem 0.66rem;
        flex: 1;
        text-align: left;
    }

    .recharge-title {
        font-size: 0.56rem;
        font-weight: 600;
        color: #262626;
    }

    .recharge-price {
        font-size: 0.72rem;
        font-family: PingFangSC;
        font-weight: 600;
        color: #E21414;
        line-height: 1.2rem;
    }

    .recharge-num-container {
        display: flex;
        align-items: center;
        width: 3.52rem;
        height: 1.2rem;
        border-radius: 0.12rem;
        border: 0.04rem solid #3d7fff;
        font-size: 0.56rem;
    }

    .recharge-num-subtract {
        flex: 1;
        border-right: 0.04rem solid #3d7fff;
        color: #3D7FFF;
        text-align: center;
        line-height: 1.2rem;
        cursor: pointer;
    }

    .recharge-num-subtract:hover,
    .recharge-num-subtract:active,
    .recharge-num-subtract:focus {
        background: #3D7FFF;
        color: #fff;
    }

    .recharge-num-add:hover,
    .recharge-num-add:active,
    .recharge-num-add:focus {
        background: #3D7FFF;
        color: #fff;
    }

    .recharge-num-subtract-disabled,
    .recharge-num-subtract-disabled:hover,
    .recharge-num-subtract-disabled:active,
    .recharge-num-subtract-disabled:focus {
        margin-left: -0.04rem;
        background: none;
        border: 0.04rem solid gray;
        border-radius: 0.12rem 0 0 0.12rem;
        color: gray;
    }

    .recharge-num {
        flex: 1;
        color: #3D7FFF;
        border-right: 0.04rem solid #3d7fff;
        text-align: center;
        line-height: 1.2rem;
    }

    .recharge-num-add {
        flex: 1;
        color: #3D7FFF;
        text-align: center;
        line-height: 1.2rem;
        cursor: pointer;
    }
    .recharge-num-add-disabled,
    .recharge-num-add-disabled:hover,
    .recharge-num-add-disabled:active,
    .recharge-num-add-disabled:focus {
        margin-left: -0.04rem;
        margin-right: -0.04rem;
        background: none;
        border: 0.04rem solid gray;
        border-radius: 0 0.12rem 0.12rem 0;
        color: gray;
    }
    .recharge-p-c {
        display: flex;
        justify-content: space-between;
    }
    .error{
        padding-left: 1.48rem;
        color: red;
        font-size: 0.48rem;
        line-height: 1.48rem;
    }
</style>
