<template>
    <div>
        <swiper ref="mySwiper" :options="swiperOptions">
            <swiper-slide><img src="../../assets/banner.png"/></swiper-slide>
            <swiper-slide><img src="../../assets/banner1.jpg"/></swiper-slide>
            <swiper-slide><img src="../../assets/banner2.jpg"/></swiper-slide>

            <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
    </div>
</template>

<script >
    //import {defineComponent} from "vue"
    import {swiper, swiperSlide} from 'vue-awesome-swiper'
    import "swiper/dist/css/swiper.min.css"


    export default {
        components: {
            swiper,
            swiperSlide,
        },
        data() {
            return {
                swiperOptions: {
                    autoplay: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    }
                },
            }
        }
    }
</script>

<style>

    .swiper-container {
        height: 15.54rem;
    }

    .swiper-wrapper {
        height: 15rem;
    }

    .swiper-container-horizontal>.swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
        bottom: 0;
        font-size: 16px;
    }

    .swiper-pagination-bullet {
        width: 0.32rem;
        height: 0.3rem;
        background: #fff;
        border-radius: 0.18rem;
        opacity: 1;
    }

    .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 0 0.2rem;
    }

    .swiper-pagination-bullet-active {
        width: 0.78rem;
        height: 0.3rem;
        background: #3D7FFF;
    }
    .swiper-slide>img{
        display: block;
        width: 15rem;
    }
</style>
